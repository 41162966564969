export default {
  accueil() {
    return {
      title: 'Lodylearn - Accueil',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Vivez une autre expérience de formation en ligne. Chaque module vous offre 8 services pour une expérience de formation optimale.',
      }],
    };
  },
  platform() {
    return {
      title: 'Lodylearn - Plateforme',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Plus qu’une plateforme de digital learning, Lodylearn, c’est une véritable approche servicielle scénarisée construite autour d’une thématique de formation.',
      }],
    };
  },
  modules() {
    return {
      title: 'Lodylearn - Modules',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Venez découvrir nos différents modules autour de diverses problématiques : réseaux sociaux, marketing digital, recrutement et bien plus encore !',
      }],
    };
  },
  module(title: string, description: string, image: string, url: string) {
    return {
      title: `Lodylearn - ${title}`,
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: description,
      }, {
        vmid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: title,
      }, {
        vmid: 'og:image',
        name: 'og:image',
        property: 'og:image',
        content: image,
      }, {
        vmid: 'og:url',
        name: 'og:url',
        property: 'og:url',
        content: url,
      }],
    };
  },
  contact() {
    return {
      title: 'Lodylearn - Contact',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Une demande particulière ? Contactez-nous par email à info@lodylearn.fr ou à travers le formulaire.',
      }],
    };
  },
  tipix() {
    return {
      title: 'Lodylearn - Tipix',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Découvrez nos experts, ils vous partageront leurs secrets et répondront à toutes vos questions !',
      }],
    };
  },
  tipixDetail(name: string, description: string) {
    return {
      title: `Lodylearn - ${name}`,
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: description,
      }],
    };
  },
  login() {
    return {
      title: 'Lodylearn - Se connecter',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Vous avez déjà un compte ? Connectez-vous !',
      }],
    };
  },
  register() {
    return {
      title: 'Lodylearn - Créer un compte',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'Créer votre compte afin d\'accéder à toutes les ressources de Lodylearn. Vous pourrez ainsi visionner nos modules et accéder à différentes ressources.',
      }],
    };
  },
};
