<template>
  <div class="module-detail pb-20" :class="{'is-page': !useInnerPadding}" ref="parentElement">
    <!-- left side -->
    <div class="module-detail-content">
      <div>
        <module-preview :module="module"></module-preview>
      </div>

      <!-- block title -->
      <div class="mt-6">
        <h2 class="text-4xl mb-2">{{ module.title }}</h2>
        <p class="whitespace-pre-wrap">{{ module.description }}</p>
      </div>
      <!-- end of block title -->

      <!-- block content -->
      <module-plan :module="module" class="mt-8"></module-plan>
      <module-group :use-padding="useInnerPadding"
                    title-start="Vos ressources & services" title-next="Plus d'informations sur le module">
        <div class="mt-10 mb-6">
          <display-resource v-for="group in displayResources" :key="group.id" :group="group" class="mb-8"
                            :in-page="inPage"></display-resource>
        </div>
      </module-group>
      <module-skills :module="module" class="mt-8"></module-skills>
      <module-participants :module="module" class="mt-8"></module-participants>
      <!-- end of block content -->

      <!-- block comments -->
      <div class="mt-8 mb-4" v-if="commentCount > 0">
        <div class="content-block-plan">
          <bc-part-title title="Avis & commentaires"></bc-part-title>
          <div class="comments-container pt-4">
            <template v-if="comments.length > 0">
              <div class="comment-item flex p-4 rounded-3xl bg-primary-900 flex-nowrap mb-4"
                   :key="comment.id"
                   v-for="comment in comments">
                <div class="profile-picture bg-primary-500 flex-grow-0 flex-shrink-0 w-48px h-48px rounded-full flex">
                  <span class="m-auto text-2xl">{{ comment.appUser.firstname[0] }}</span>
                </div>
                <div class="comment-content flex flex-col flex-grow ml-4 mt-0 mr-4">
                  <div class="font-light">
                    <strong class="font-medium">{{ comment.appUser.firstname }}</strong>, le
                    {{ comment.date | moment('DD MMMM YYYY') }}
                  </div>
                  <div class="italic font-light">{{ comment.content }}</div>
                </div>
                <div class="note flex flex-nowrap items-start">
                  <span class="font-title text-3xl mr-1 my-auto leading-4 pt-2">{{ comment.note }}</span>
                  <svg class="my-auto" xmlns="http://www.w3.org/2000/svg" width="22.34" height="21.247"
                       viewBox="0 0 22.34 21.247">
                    <path id="Path_61" data-name="Path 61"
                          d="M48.464,40.363,51.1,48.479h8.533l-6.9,5.016,2.637,8.116-6.9-5.016-6.9,5.016L44.2,53.494l-6.9-5.016h8.533Z"
                          transform="translate(-37.294 -40.363)" fill="#fff"/>
                  </svg>
                </div>
              </div>
              <div class="mt-2" v-if="commentCount > 2">
                <button class="flex items-center cursor-pointer" @click="commentShown = commentCount"
                        v-if="commentShown < commentCount">
                  <span class="mr-2 text-sm">Plus de commentaires</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.496" height="8.248" viewBox="0 0 14.496 8.248">
                    <path id="Path_19" data-name="Path 19" d="M-358.772,833.006l5.834,5.834,5.834-5.834"
                          transform="translate(360.186 -831.592)" fill="none" stroke="#fff" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2"/>
                  </svg>
                </button>
                <button class="flex items-center cursor-pointer" @click="commentShown = 2" v-else>
                  <span class="mr-2 text-sm">Moins de commentaires</span>
                  <svg class="rotate-180 transform" xmlns="http://www.w3.org/2000/svg" width="14.496" height="8.248"
                       viewBox="0 0 14.496 8.248">
                    <path id="Path_19" data-name="Path 19" d="M-358.772,833.006l5.834,5.834,5.834-5.834"
                          transform="translate(360.186 -831.592)" fill="none" stroke="#fff" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2"/>
                  </svg>
                </button>
              </div>
            </template>
            <template v-if="comments.length === 0">
              <span class="italic text-sm">Il n'y a pas encore de commentaires sur ce module</span>
            </template>
          </div>
        </div>
      </div>
      <!-- end of block comments -->
    </div>
    <!-- end of left side -->
    <!-- right side -->
    <div class="module-detail-informations" :class="{'ml-8': useInnerPadding}">
      <!-- informations block -->
      <div class="informations bg-primary-900 flex flex-col p-8 px-12 pt-4 rounded-b-3xl sm-no-round">
        <div class="level flex-shrink-0 mx-auto">
          <div class="square rounded-lg bg-primary-500 w-58px h-58px transform -rotate-4 flex">
            <img :src="difficultyPicture" width="52" height="52" class="transform rotate-4 m-auto flex-shrink-0">
          </div>
          <span class="block transform -rotate-4 text-white text-xs">{{ difficultyName }}</span>
        </div>
        <div class="module-title text-white text-2xl mt-4 mx-auto text-center leading-6">
          <h2>{{ module.title }}</h2>
        </div>
        <div class="flex flex-col">
          <module-meta hour-bg="bg-primary-800"
                       class="m-auto mt-4"
                       :absolute="false"
                       :resources-count="module.resourcesCount"
                       :hours="module.hours"
                       :bottom-padding="false"></module-meta>
        </div>
        <div class="flex mt-4 wide-hide-when-scrolled" :class="{scrolled: hasScroll}">
          <module-price :module="module" class="m-auto"></module-price>
        </div>
        <div class="button-group mt-4 mx-0 wide-hide-when-scrolled" :class="{scrolled: hasScroll}">
          <button @click="addToCart"
                  class="bg-primary-500 flex rounded-full text-center py-3 w-full transition hover:bg-white hover:text-primary-900">
            <span class="m-auto">Go !</span>
          </button>
        </div>
      </div>

      <!-- end of informations block -->
      <div class="more-informations">
        <!-- infos module -->
        <div class="modules mt-6 flex-grow " v-if="linkedModules.length > 0">
          <div class="flex flex-row mb-4 h-auto  items-center">
            <div class="square rounded-lg  w-40px h-40px transform -rotate-4 flex">
              <img :src="unlimited" width="40" height="40" class="transform rotate-4 m-auto flex-shrink-0">
            </div>
            <p class="ml-5" >Accès illimité à vie</p>
          </div>
          <div class="flex flex-row mb-4 h-auto  items-center">
            <div class="square rounded-lg  w-40px h-40px transform -rotate-4 flex">
              <img :src="computer" width="40" height="40" class="transform rotate-4 m-auto flex-shrink-0">
            </div>
            <p class="ml-5" >100% en ligne</p>
          </div>
          <div class="flex flex-row mb-4 h-auto  items-center">
            <div class="square rounded-lg  w-40px h-4-px transform -rotate-4 flex">
              <img :src="thumbUpBlinking" width="40" height="40" class="transform rotate-4 m-auto flex-shrink-0">
            </div>
              <p class="ml-5 " >Avancez à votre rythme</p>
          </div>
          <div class="flex flex-row mb-4 h-auto  items-center">
            <div class="square rounded-lg  w-40px h-40px transform -rotate-4 flex">
              <img :src="reload" width="40" height="40" class="transform rotate-4 m-auto flex-shrink-0">
            </div>
              <p class="ml-5 ">Mis à jour le {{formatDate(module.modified)}}</p>
          </div>
        </div>
        <!-- end infos module -->
        <!-- prerequis -->
        <div class="mt-6 flex-grow" v-if="prerequisites.length > 0">
          <h3 class="text-2xl leading-6 font-title tracking-wide">Prérequis</h3>
          <ul>
            <li class="items-center flex flex-nowrap mt-2" v-for="prerequisite in prerequisites"
                :key="prerequisite.id">
              <svg class="flex-shrink-0 flex-grow-0 mr-4 text-primary-500 stroke-current"
                   xmlns="http://www.w3.org/2000/svg" width="21.178" height="17.922" viewBox="0 0 21.178 17.922">
                <path id="Path_55" data-name="Path 55" d="M-2745.8,3347.878l6.736,5.773,8.847-13.146"
                      transform="translate(2748.617 -3337.729)" fill="none" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="4"/>
              </svg>
              <span class="italic font-light">{{ prerequisite.label }}</span>
            </li>
          </ul>
        </div>
        <!-- end of prerequis -->
        <!-- modules associés -->
        <div class="modules mt-6 flex-grow" v-if="linkedModules.length > 0">
          <h3 class="text-2xl leading-6 font-title tracking-wide mb-4">Modules associés</h3>
          <router-link
              custom
              v-slot="{ navigate }"
              v-for="short in linkedModules" :key="short.id"
              :to="short.isPaid ? {name: $routeNames.AccountModuleDetail, params: {id: short.id}} : { name: $routeNames.ModuleDetails, params: { slug: short.slug }}">
            <div role="link" @click="navigate"
                class="module-short flex flex-nowrap items-center mb-4 cursor-pointer">
              <div
                  class="image-container bg-no-repeat bg-cover bg-center flex-grow-0 flex-shrink-0 w-112px rounded h-79px ml-0 mr-2"
                  :style="`background-image: url(${short.picture.getPath({w: 700, h: 400, crop: true})})`"></div>
              <div class="mx-0 my-auto">
                <div class="text-sm font-medium leading-4 mb-1 clamp-3">
                  {{ short.title }}
                </div>
                <div class="text-xs font-light leading-3">
                  {{ short.resourcesCount }} ressource{{ short.resourcesCount > 1 ? 's' : '' }}
                </div>
              </div>
            </div>
          </router-link>
          <div class="mt-2" v-if="linkedCount > 3">
            <button class="flex items-center cursor-pointer" @click="linkedShown = linkedCount"
                    v-if="linkedShown < linkedCount">
              <span class="mr-2 text-sm">Plus de modules</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="14.496" height="8.248" viewBox="0 0 14.496 8.248">
                <path id="Path_19" data-name="Path 19" d="M-358.772,833.006l5.834,5.834,5.834-5.834"
                      transform="translate(360.186 -831.592)" fill="none" stroke="#fff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"/>
              </svg>
            </button>
            <button class="flex items-center cursor-pointer" @click="linkedShown = 3" v-else>
              <span class="mr-2 text-sm">Moins de modules</span>
              <svg class="rotate-180 transform" xmlns="http://www.w3.org/2000/svg" width="14.496" height="8.248"
                   viewBox="0 0 14.496 8.248">
                <path id="Path_19" data-name="Path 19" d="M-358.772,833.006l5.834,5.834,5.834-5.834"
                      transform="translate(360.186 -831.592)" fill="none" stroke="#fff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"/>
              </svg>
            </button>
          </div>
        </div>
        <!-- end of modules associés -->
        <!-- keywords -->
        <div class="keywords mt-6 flex-grow">
          <h3 class="text-2xl leading-6 font-title tracking-wide mb-4">Tags</h3>
          <div class="flex flex-wrap">
            <div class="keyword px-3 py-1 rounded-full border border-white  italic text-sm mr-2 mb-2 font-light"
                 v-for="keyword in keywords" :key="keyword.id">
              {{ keyword.label }}
            </div>
          </div>
          <button
              class="mt-2 cursor-pointer bg-primary-500 text-white rounded-full rounded-full hover:bg-opacity-80 italic text-sm px-3 py-1"
              v-if="keywordShown < keywordCount"
              @click="keywordShown = keywordCount">
            {{ keywordCount - keywordShown }}+
          </button>
        </div>
        <!-- end of keywords -->
      </div>
    </div>
    <!-- end of right side -->

    <!-- bottom pricebar -->
    <div class="pricebar flex lg:hidden fixed bottom-0 left-0 right-0 z-20 bg-primary-900 px-8 py-2"
         :class="{scrolled: hasScroll}">
      <module-price :module="module" :inline="true" class="ml-auto mt-auto mr-4 mb-auto flex-grow-0 flex-shrink-0"></module-price>
      <div class="button-container my-auto mr-auto ml-0.5">
        <button @click="addToCart"
                class="bg-primary-500 flex rounded-full text-center py-2 px-4 transition hover:bg-white hover:text-primary-900">
          <span class="m-auto whitespace-nowrap">Acheter maintenant</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { resolve } from '../../_helpers/image.helper';
import dateMixin from '@/mixins/dateMixin';

const expert = resolve('level/Icone-Niveaux_Expert_blanc.svg');
const intermediate = resolve('level/Icone-Niveaux_Intermediaire_blanc.svg');
const beginner = resolve('level/Icone-Niveaux_Debutant_blanc.svg');

const computer = resolve('module-infos/computer.svg');
const reload = resolve('module-infos/reload.svg');
const thumbUpBlinking = resolve('module-infos/thumb_up_blinking.svg');
const unlimited = resolve('module-infos/unlimited.svg');

@Component({
  name: 'module-details',
  components: {
    ModulePreview: () => import(/* webpackPrefetch: true */ './module-preview.vue'),
    DisplayResource: () => import(/* webpackPrefetch: true */ './display-resource.vue'),
    ModuleGroup: () => import(/* webpackPrefetch: true */ './module-group.vue'),
    ModulePrice: () => import(/* webpackPrefetch: true */ '../module-widget/module-price.vue'),
    ModuleMeta: () => import(/* webpackPrefetch: true */ '../module-widget/module-meta.vue'),
    ModuleParticipants: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-participants.vue'),
    ModuleSkills: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-skills.vue'),
    ModulePlan: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-plan.vue'),
    BcPartTitle: () => import(/* webpackPrefetch: true */ '@/components/block-content/bc-part-title.vue'),
  },
  props: {
    module: {
      required: true,
    },
    inPage: {
      required: false,
      type: Boolean,
      default: false,
    },
    asPage: {
      required: false,
      type: Boolean,
      default: false,
    },
    useInnerPadding: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      linkedShown: 3,
      keywordShown: 6,
      commentShown: 2,
      hasScroll: false,
      elementRef: window,
      unlimited,
      computer,
      thumbUpBlinking,
      reload,
    };
  },
  mounted() {
    if (!this.asPage) {
      this.elementRef = this.$refs.parentElement.closest('.module-widget-modal');
    }
    this.elementRef.addEventListener('scroll', this.onScroll, { passive: true });
  },
  beforeDestroy() {
    this.elementRef.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    displayResources() {
      return this.module.displayResources;
    },
    keywordCount() {
      return this.module.keywords.length;
    },
    commentCount() {
      return this.module.comments.length;
    },
    prerequisites() {
      return this.module.modulePrerequisites;
    },
    linkedCount() {
      return this.module ? this.module.moduleLinks.length : 0;
    },
    storeModules() {
      return this.module.moduleLinks.sort((a, b) => a.display_order - b.display_order).map((a) => (a.module));
    },
    storeKeywords() {
      return this.module.keywords;
    },
    comments() {
      return this.module.comments.slice(0, this.commentShown);
    },
    keywords() {
      return this.storeKeywords.slice(0, this.keywordShown);
    },
    linkedModules() {
      return this.storeModules.slice(0, this.linkedShown);
    },
    hasMoreLinked() {
      return this.linkedCount > this.linkedShown;
    },
    difficultyPicture() {
      if (this.module.difficulty === 'expert') return expert;
      if (this.module.difficulty === 'intermediate') return intermediate;
      return beginner;
    },
    difficultyName() {
      if (this.module.difficulty === 'beginner') return 'Débutant';
      if (this.module.difficulty === 'intermediate') return 'Intermédiaire';
      return 'Expert';
    },
  },
  mixins: [dateMixin],
  methods: {
    onScroll() {
      if (this.elementRef === window) {
        this.hasScroll = this.elementRef.top.scrollY > 0;
        return;
      }
      this.hasScroll = this.elementRef.scrollTop > 0;
    },
    async addToCart() {
      await this.$store.dispatch('shoppingCart/modal/setModule', this.module.id);
    },
  },
})
export default class ModuleDetails extends Vue {
}
</script>

<style scoped>

</style>
