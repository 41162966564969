import { render, staticRenderFns } from "./module-details.vue?vue&type=template&id=2e45aba2&scoped=true&"
import script from "./module-details.vue?vue&type=script&lang=js&"
export * from "./module-details.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e45aba2",
  null
  
)

export default component.exports