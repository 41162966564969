<template>
  <div class="page profile-page bg-primary-800 min-h-footer w-full text-white">
    <div class="flex flex-nowrap w-page my-0 mx-auto page-padding">
      <module-details v-if="!loading && module" :module="module" :is-page="false" :as-page="true"></module-details>
      <div v-if="loading || !module" class="p-8 mx-auto flex-grow">
        <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                  repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { mapState } from 'vuex';
import ModuleDetails from '@/components/module-details/module-details.vue';
import MetaHelper from '../../_helpers/meta.helper';

@Component({
  name: 'ModulePage',
  metaInfo() {
    return this.meta;
  },
  components: {
    ModuleDetails,
  },
  async mounted() {
    await this.$store.dispatch('modulePage/open', this.$route.params.slug);
  },
  computed: {
    meta() {
      if (this.module) {
        return MetaHelper.module(this.module.title, this.module.description.slice(0, 150), this.module.picture.getPath({ w: 1200, h: 627, crop: true }), this.$route.fullPath);
      }
      return MetaHelper.modules();
    },
    ...mapState({
      loading: (s) => s.modulePage.loading,
      module: (s) => s.modulePage.module,
    }),
  },
})
export default class ModulePage extends Vue {
}
</script>

<style scoped>

</style>
